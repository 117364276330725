import { render, staticRenderFns } from "./balanceorders.vue?vue&type=template&id=8366a2aa&"
import script from "./balanceorders.vue?vue&type=script&lang=js&"
export * from "./balanceorders.vue?vue&type=script&lang=js&"
import style0 from "./balanceorders.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports